import React, { useEffect, useState } from "react";
import FilmFestivalHeader from "../../components/FilmFestival/Header";
import Layout from "../../components/Layout";
import HeroSection from "../../components/FilmFestival/HeroSection";
import { isMobile } from "react-device-detect";
import { apiRoutes } from "../../constants/apiRoutes";
import axios from "axios";
import { appendImage, createMarkup } from "../../utils";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";
import routes from "../../components/constants/routes";
import { Tab, Tabs, Modal, Button } from "react-bootstrap";
import { ImageConstants } from "../../components/constants/Imageconstants";
import { Cookies } from "react-cookie";
import { navigate } from "gatsby";

const Myprofile = () => {
  const [registerData, setRegisterData] = useState({});
  const fetchRegister = async () => {
    try {
      const constactres = await axios.get(apiRoutes.register);
      setRegisterData(constactres.data.data.attributes);
    } catch (error) {}
  };
  useEffect(() => {
    fetchRegister();
  }, []);

  const formHeightWeb = registerData?.filmFestival_formHeightWeb ?? "250";
  const formHeightMob = registerData?.filmFestival_formHeightMob ?? "270";

  const [fistname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [addressone, setAddressone] = useState("");
  const [addresstwo, setAddresstwo] = useState("");
  const [city, setCity] = useState("");
  const [postalcode, setPostelcode] = useState("");
  const [country, setCountry] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [brochure, setBrochure] = useState(false);
  const [addressshow, setAddressshow] = useState(false);
  const [datasucess, setDatasucess] = useState(false);
  const [dataerror, setDataerror] = useState(false);
  const [adderrors, setAdderrors] = useState(false);

  const [fistnameshow, setFistnameshow] = useState(true);
  const [lastnameshow, setLastnameshow] = useState(true);
  const [addfirstshow, setAddfirstshow] = useState(true);

  const [finalSubmit, setFinalsubmit] = useState(true);
  const [validUserName, setValidUserName] = useState(true);

  const [othercountry, setOthercountry] = useState("");
  const [showothercountry, setShowothercountry] = useState(false);
  //My profile
  const cookies = new Cookies();
  useEffect(() => {
    appendImage("rich-editor");
  }, [registerData]);

  const seoData = {
    title: "Register for Film Festivals Luxembourg | Book Your Tickets",
    description:
      "Register for Film Festivals Luxembourg to secure your tickets. Join us for exclusive screenings, discussions, and cinematic experiences. Reserve your seat now.",
    url: `${process.env.GATSBY_SITE_URL}${routes.FFRegister}/`,
  };
  const setTabStatus = (route) => {
    navigate(route);
  };
  const fetchAllUsers = () => {
    const token = localStorage.getItem("token");
    axios
      .get(process.env.GATSBY_API_LOGIN_URL + "/api/user", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((resopnse) => {
        console.log(resopnse.message, "user abc");

        setFirstname(resopnse.data.data.user_info?.first_name);
        setLastname(resopnse.data.data.user_info?.last_name);
        setEmail(resopnse.data.data.user_info?.email);
        setAddressone(resopnse.data.data.user_info?.address1);
        setAddresstwo(resopnse.data.data.user_info?.address2);
        setCity(resopnse.data.data.user_info?.city);
        setPostelcode(resopnse.data.data.user_info?.postal_code);
        setCountry(resopnse.data.data.user_info?.country);
        setNewsletter(resopnse.data.data.user_info?.newsletter);
        setBrochure(resopnse.data.data.user_info?.brochure);

        if (resopnse.data.data.user_info?.brochure) {
          setAddressshow(true);
        } else {
          setAddressshow(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  useEffect(() => {
    fetchAllUsers();
  }, []);

  //Updated info
  const updateuser = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (brochure === true) {
      if (
        addressone.length < 1 ||
        city.length < 1 ||
        postalcode.length < 1 ||
        country.length < 1 ||
        country === "Other"
      ) {
        setAdderrors(true);
      } else {
        axios
          .post(
            process.env.GATSBY_API_LOGIN_URL + "/api/update",
            {
              first_name: fistname,
              last_name: lastname,
              email: email,
              address1: addressone,
              address2: addresstwo,
              city: city,
              postal_code: postalcode,
              country: country,
              newsletter: newsletter,
              brochure: brochure,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((resopnse) => {
            console.log(resopnse.data.message);
            setDatasucess(resopnse.data);
            setAdderrors(false);
          })
          .catch((error) => {
            console.log(error);
            setDataerror(error.response.data.data);
          });
      }
    } else {
      axios
        .post(
          process.env.GATSBY_API_LOGIN_URL + "/api/update",
          {
            first_name: fistname,
            last_name: lastname,
            email: email,
            address1: addressone,
            address2: addresstwo,
            city: city,
            postal_code: postalcode,
            country: country,
            newsletter: newsletter,
            brochure: brochure,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((resopnse) => {
          console.log(resopnse.data.message);
          setDatasucess(resopnse.data);
        })
        .catch((error) => {
          console.log(error);
          setDataerror(error.response.data.data);
        });
    }
  };

  const handlefirstname = (e) => {
    setValidUserName(false);
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");

    setFirstname(sanitizedValue);
  };
  const handlelastname = (e) => {
    setValidUserName(false);
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
    setLastname(sanitizedValue);
  };
  const handleaddressone = (e) => {
    setAddressone(e.target.value);
  };
  const handleaddresstwo = (e) => {
    setAddresstwo(e.target.value);
  };
  const handlecity = (e) => {
    setCity(e.target.value);
  };
  const handlepostalcode = (e) => {
    setPostelcode(e.target.value);
  };
  const handlesingupcountry = (e) => {
    if (e.target.value !== "Other") {
      setShowothercountry(false);
      setCountry(e.target.value);
    } else {
      setShowothercountry(true);
      setCountry(e.target.value);
    }
  };
  const handleothercountry = (e) => {
    setOthercountry(e.target.value);
    setCountry(e.target.value);
  };
  const Handlernews = () => {
    setNewsletter(!newsletter);
    setFinalsubmit(false);
  };
  const Handlerbrochure = () => {
    setBrochure(!brochure);
    setAddressshow(!addressshow);
    setFinalsubmit(false);
  };
  const logout = () => {
    cookies.remove("token");
    localStorage.removeItem("token");
    setTabStatus(routes.FFRegister);
  };

  const handlershowfirsname = () => {
    setFistnameshow(false);
    setFinalsubmit(false);
  };
  const handlershowlastname = () => {
    setLastnameshow(false);
    setFinalsubmit(false);
  };

  const HandlerAddshow = () => {
    setAddfirstshow(false);
    setFinalsubmit(false);
  };
  return (
    <Layout>
      <Helmet
        title={seoData.title}
        description={seoData.description}
        url={seoData?.url}
      />

      <div className="film-festival">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pr-mob-0">
              <HeroSection />
            </div>

            <div className="col-12 head-tab mt-40">
              <FilmFestivalHeader />

              {Object.values(registerData).length ? (
                <>
                  {" "}
                  <div className="container">
                    <div
                      className="my-4 rich-editor"
                      dangerouslySetInnerHTML={createMarkup(
                        registerData?.flim_festival_description
                      )}
                    ></div>
                  </div>
                  <div className="myProfilemain">
                    <div className="myprofile">
                      <div className="header">
                        <h2>My Profile</h2>
                        <div className="logout">
                          <span>Logout</span>
                          <button className="btn" onClick={logout}>
                            <img
                              src={ImageConstants.logoutIcon}
                              alt="log out"
                            />
                          </button>
                        </div>
                      </div>
                      <form>
                        <div className="personlaDetails">
                          <div className="row mb-2">
                            <div className="col-md-3 col-12 offset-md-2">
                              <label htmlFor="FirstName">First Name:</label>
                            </div>
                            <div className="col-md-6 col-11 pl-md-0">
                              <input
                                type="text"
                                className="form-control"
                                name=" First Name"
                                value={fistname}
                                onChange={handlefirstname}
                                disabled={fistnameshow}
                                invalid={
                                  !fistname.trim().length && !validUserName
                                }
                              />
                              {dataerror ? (
                                <p className="errors">{dataerror.first_name}</p>
                              ) : null}
                            </div>
                            <div className="col-1 pl-0">
                              <div
                                className="icon"
                                onClick={handlershowfirsname}
                              >
                                {fistnameshow ? (
                                  <img
                                    src={ImageConstants.editIcon}
                                    alt="edit"
                                  />
                                ) : (
                                  <img
                                    src={ImageConstants.editIcondis}
                                    alt="edit"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-3 col-12 offset-md-2">
                              <label htmlFor="LastName">Last Name:</label>
                            </div>
                            <div className="col-md-6 col-11 pl-md-0">
                              <input
                                type="text"
                                className="form-control"
                                name="Last Name"
                                value={lastname}
                                onChange={handlelastname}
                                disabled={lastnameshow}
                                invalid={
                                  !lastname.trim().length && !validUserName
                                }
                              />
                              {dataerror ? (
                                <p className="errors">{dataerror.last_name}</p>
                              ) : null}
                            </div>
                            <div className="col-1 pl-0">
                              <div
                                className="icon"
                                onClick={handlershowlastname}
                              >
                                {lastnameshow ? (
                                  <img
                                    src={ImageConstants.editIcon}
                                    alt="edit"
                                  />
                                ) : (
                                  <img
                                    src={ImageConstants.editIcondis}
                                    alt="edit"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-3 col-12 offset-md-2">
                              <label htmlFor="Email">Email:</label>
                            </div>
                            <div className="col-md-6 col-11 pl-md-0">
                              <input
                                type="email"
                                className="form-control"
                                name="Email"
                                value={email}
                                disabled
                              />
                            </div>
                            <div className="col-1 pl-0"></div>
                          </div>
                        </div>

                        <div className="addressDetails">
                          <div className="row">
                            <div className="col-md-5 col-12">
                              <label className="would-u-like">
                                I would like to receive:
                              </label>
                            </div>
                            <div className="col-md-6 col-12 pl-md-0">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue
                                  id="eNewsletters"
                                  checked={newsletter}
                                  onChange={Handlernews}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="eNewsletters"
                                >
                                  eNewsletters
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-12 pl-md-0 offset-md-5">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue
                                  id="Printed"
                                  checked={brochure}
                                  onChange={Handlerbrochure}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Printed"
                                >
                                  Printed brochures by post
                                </label>
                              </div>
                            </div>
                          </div>
                          {addressshow ? (
                            <div className="row">
                              <div className="col-md-6 col-12 pl-md-0 offset-md-5">
                                <div className="form-group">
                                  <div className="addressHeader">
                                    <label htmlFor="name">
                                      <img
                                        src={ImageConstants.addressIcon}
                                        alt="user icon"
                                      />
                                      Address <span>*</span>
                                    </label>
                                    <div
                                      className="editIcon"
                                      onClick={HandlerAddshow}
                                    >
                                      {addfirstshow ? (
                                        <img
                                          src={ImageConstants.editIcon}
                                          alt="icon"
                                        />
                                      ) : (
                                        <img
                                          src={ImageConstants.editIcondis}
                                          alt="edit"
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name="confirmPassword"
                                    placeholder="Address 1"
                                    value={addressone}
                                    onChange={handleaddressone}
                                    disabled={addfirstshow}
                                  />
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name="confirmPassword"
                                    placeholder="Address 2"
                                    value={addresstwo}
                                    onChange={handleaddresstwo}
                                    disabled={addfirstshow}
                                  />
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name="confirmPassword"
                                    placeholder="Town/City"
                                    value={city}
                                    onChange={handlecity}
                                    disabled={addfirstshow}
                                  />
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name="confirmPassword"
                                    placeholder="Postal Code"
                                    value={postalcode}
                                    onChange={handlepostalcode}
                                    disabled={addfirstshow}
                                  />
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    onChange={handlesingupcountry}
                                    Value={country}
                                    disabled={addfirstshow}
                                  >
                                    <option>{country || "Country"}</option>
                                    <option value="Luxembourg">
                                      Luxembourg
                                    </option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="France">France</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Other">
                                      Other (Please specify)
                                    </option>
                                  </select>
                                  {showothercountry ? (
                                    <input
                                      type="text"
                                      className="form-control mb-2 mt-2"
                                      name="other"
                                      placeholder="Please specify"
                                      value={othercountry}
                                      onChange={handleothercountry}
                                    />
                                  ) : null}

                                  {adderrors ? (
                                    <p className="errors">
                                      Please fill Address details
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="row">
                            <div className="col-12 mt-3 text-center">
                              {datasucess ? (
                                <p className="text-success mb-0">
                                  {datasucess.message}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-6 col-12 pl-md-0 offset-md-5 updatebtn">
                              <div className="form-group">
                                <button
                                  className="btn btn-submit"
                                  onClick={updateuser}
                                  disabled={finalSubmit}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="login-bottom">
                        <img src={ImageConstants.loginbg} alt="login" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-center" style={{ margin: "15em 0" }}></p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Myprofile;
