import React, { useState, useEffect } from "react";
import routes from "../constants/routes";
import { navigate } from "gatsby";
import { Cookies } from "react-cookie";

const FilmFestivalHeader = () => {
  const cookies = new Cookies();
  const [path, setPath] = useState(undefined);
  const [scroll, setScroll] = useState(false);

  const setTabStatus = (route) => {
    navigate(route);
  };
  const [hidetabs, setHidetabs] = useState(false);

  const handletab = () => {
    const token = localStorage.getItem("token");
    if (token) {
      setHidetabs(true);
    } else {
      setHidetabs(false);
    }
  };
  useEffect(() => {
    handletab();
  }, []);
  useEffect(() => {
    const tempPath =
      typeof window !== undefined ? window.location.href : undefined;
    setPath(tempPath);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 341);
    });
  }, []);

  return (
    <nav className={scroll ? "nav-header ffstickyheader" : "nav-header"}>
      <div className="nav nav-tabs mob-hide" id="nav-tab" role="tablist">
        <button
          onClick={() => setTabStatus(routes.FFAbout)}
          className={`nav-link ${
            path?.includes(routes.FFAbout) ? "active" : ""
          }`}
        >
          About
        </button>
        <button
          onClick={() => setTabStatus(routes.FFScreenings)}
          className={`nav-link ${
            path?.includes(routes.FFScreenings) ? "active" : ""
          }`}
          id="nav-screenings-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-screenings"
          type="button"
          role="tab"
          aria-controls="nav-screenings"
          aria-selected="false"
        >
          Schedule
        </button>
        <button
          onClick={() => setTabStatus(routes.FFCurrentSeason)}
          className={`nav-link ${
            path?.includes(routes.FFCurrentSeason) ? "active" : ""
          }`}
          id="nav-season-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-season"
          type="button"
          role="tab"
          aria-controls="nav-season"
          aria-selected="false"
        >
          Current Screenings
        </button>
        <button
          onClick={() => setTabStatus(routes.FFPastSeason)}
          className={`nav-link ${
            path?.includes(routes.FFPastSeason) ? "active" : ""
          }`}
          id="nav-past-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-past"
          type="button"
          role="tab"
          aria-controls="nav-past"
          aria-selected="false"
        >
          Past Screenings
        </button>
        <button
          onClick={() => setTabStatus(routes.FFNews)}
          className={`nav-link ${
            path?.includes(routes.FFNews) ? "active" : ""
          }`}
          id="nav-news-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-news"
          type="button"
          role="tab"
          aria-controls="nav-news"
          aria-selected="false"
        >
          News
        </button>
        {!hidetabs ? (
          <button
            onClick={() => setTabStatus(routes.FFRegister)}
            className={`nav-link ${
              path?.includes(routes.FFRegister) ? "active" : ""
            }`}
            id="nav-register-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-register"
            type="button"
            role="tab"
            aria-controls="nav-register"
            aria-selected="false"
          >
            Sign Up / Sign In
          </button>
        ) : (
          <button
            onClick={() => setTabStatus(routes.FFMyprofile)}
            className={`nav-link ${
              path?.includes(routes.FFMyprofile) ? "active" : ""
            }`}
            id="nav-register-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-register"
            type="button"
            role="tab"
            aria-controls="nav-register"
            aria-selected="false"
          >
            My profile
          </button>
        )}
      </div>
    </nav>
  );
};

export default FilmFestivalHeader;
