import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiRoutes } from "../../constants/apiRoutes";
import { createImage } from "../../utils";

const HeroSection = () => {
  const [header, setHeader] = useState({});
  const fetchHeader = async () => {
    try {
      const { data } = await axios.get(apiRoutes.header);
      if (data) {
        setHeader(data.data.attributes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchHeader();
  }, []);

  return (
    <div className="hero-section">
      {Object.values(header)?.length ? (
        <div className="herosectionimg">
          <img
            src={createImage(
              header?.flim_festival_image?.data?.attributes?.url
            )}
            alt={header?.flim_festival_image_alt_text}
            className="mr-30"
            width={"auto"}
            height={"auto"}
          />
          <img
            src={createImage(header?.flim_festival_logo?.data?.attributes?.url)}
            alt={header?.flim_festival_logo_alt_text}
            className="mr-30"
            width={"auto"}
            height={"auto"}
          />
          <img
            src={createImage(
              header?.flim_festival_country?.data?.attributes?.url
            )}
            alt={header?.flim_festival_country_alt_text}
            width={"auto"}
            height={"auto"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HeroSection;
